<template>
  <Layout>
    <PageHeader :title="title" :breadcrumbs="breadcrumbs" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="orderList">
          <div class="card-header border-0">
            <div class="d-flex align-items-center">
              <h5 class="card-title mb-0 flex-grow-1">Achievements</h5>
              <div class="flex-shrink-0">
                <button class="btn btn-soft-danger me-1" @click="deleteMultiple">
                  <i class="ri-delete-bin-2-line"></i>
                </button>
                <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#createCouponsModal">
                  <i class="ri-add-line align-bottom me-1"></i> Create Code
                </button>
              </div>
            </div>
          </div>
          <div class="card-body border border-dashed border-end-0 border-start-0">
            <form>
              <div class="row g-3 filters-form">
                <div class="col-xxl-5 col-sm-6">
                  <div class="search-box">
                    <input type="text" class="form-control search"
                      placeholder="Search code" />
                    <i class="ri-search-line search-icon"></i>
                  </div>
                </div>
                <!--end col-->
                <div class="col-xxl-2 col-sm-4">
                  <button type="button" class="btn btn-primary" @click="search">
                    <i class="ri-equalizer-fill align-bottom me-1"></i> Search
                  </button>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </form>
          </div>
          <div class="card-body pt-0">
            <div>
              <ul class="nav nav-tabs nav-tabs-custom nav-success mb-3" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active All py-3" data-bs-toggle="tab" id="All" href="#home1" role="tab"
                    aria-selected="true">
                    <i class="ri-team-line me-1 align-bottom"></i> All Codes
                    <span class="badge bg-info align-middle ms-1">{{ couponsList.length }}</span>
                  </a>
                </li>
              </ul>

              <div class="table-responsive table-card mb-1">
                <table class="table table-nowrap align-middle" id="orderTable">
                  <thead class="text-muted table-light">
                    <tr class="text-uppercase">
                      <th scope="col" style="width: 25px">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" id="checkAll" value="option" />
                        </div>
                      </th>
                      <th class="sort" data-sort="code">Code</th>
                      <th class="sort" data-sort="enabled">Enabled</th>
                      <th class="sort" data-sort="points">Points</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody class="list form-check-all" v-for="(record, index) of resultQuery" :key="index">

                    <tr>
                      <th scope="row">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" name="chk_child" value="option1" />
                        </div>
                      </th>
                      <td class="code">
                        <router-link to="#" class="fw-medium link-primary">{{ record.code }}
                        </router-link>
                      </td>
                      <td class="authorize">
                        <div class="form-check form-switch form-switch-sm">
                          <input
                            class="form-check-input code-switcher"
                            type="checkbox"
                            :checked="record.authorize"
                            @change="(event) => couponsEnable(event, record)"
                          />
                        </div>
                      </td>
                      <td class="points">
                        {{ record.couponInfo && record.couponInfo.points || 1 }}
                      </td>
                      <td>
                        <ul class="list-inline hstack gap-2 mb-0">
                          <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover"
                            data-bs-placement="top" title="Edit">
                            <a class="text-primary d-inline-block edit-item-btn" data-bs-toggle="modal"
                              href="#EditModal" @click="editCoupon(record)">
                              <i class="ri-pencil-fill fs-16"></i>
                            </a>
                          </li>
                          <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover"
                            data-bs-placement="top" title="Remove">
                            <a class="text-danger d-inline-block remove-item-btn" @click="deletedata(data)">
                              <i class="ri-delete-bin-5-fill fs-16"></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                      <!-- </div> -->
                    </tr>
                  </tbody>
                </table>
                <div class="noresult" style="display: none">
                  <div class="text-center">
                    <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                      :height="75" :width="75" />
                    <h5 class="mt-2">Sorry! No Result Found</h5>
                    <p class="text-muted">
                      We've searched more than 150+ achievements We did not find any
                      achievement for you search.
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end mt-3">
                <div class="pagination-wrap hstack gap-2">
                  <a class="page-item pagination-prev disabled" href="#" v-if="page != 1" @click="page--">
                    Previous
                  </a>
                  <ul class="pagination listjs-pagination mb-0">
                    <li v-for="(pageNumber, index) in pages.slice(
                        page - 1,
                        page + 5
                      )" :key="index" @click="page = pageNumber" :class="{
                              active: pageNumber == page,
                              disabled: pageNumber == '...',
                            }">
                      <a class="page" href="#">{{ pageNumber }}</a>
                    </li>
                  </ul>
                  <a class="page-item pagination-next" href="#" @click="page++" v-if="page < pages.length">
                    Next
                  </a>
                </div>
              </div>
            </div>
            <div class="modal fade" id="createCouponsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header bg-light p-3">
                    <h5 class="modal-title" id="exampleModalLabel">Add Code</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                      id="close-modal"></button>
                  </div>
                  <form action="#" id="addform">
                    <div class="modal-body">

                      <div class="mb-3" id="modal-id">
                        <label for="code" class="form-label">Code</label>
                        <input type="text" id="code" class="form-control" placeholder="Coupon Code" required
                          maxlength="50" />
                      </div>

                      <div class="mb-3" id="modal-id">
                        <label for="points" class="form-label">Points</label>
                        <input type="text" id="points" class="form-control" placeholder="Coupon points" required
                          maxlength="1" />
                      </div>

                      <div class="mb-3">
                        <label for="authorize" class="form-label">Enable code after creation</label>
                        <div class="form-check form-switch form-switch-sm">
                          <input
                            class="form-check-input code-switcher"
                            type="checkbox"
                            id="authorize"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                        <button type="button" class="btn btn-light" data-bs-dismiss="modal" id="closemodal">
                          Close
                        </button>
                        <button type="button" class="btn btn-success" id="add-btn" @click="createCoupon">
                          Add Achievement
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div class="modal fade" id="EditModal" tabindex="-1" aria-labelledby="exampleModalLabels"
              aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header bg-light p-3">
                    <h5 class="modal-title" id="exampleModalLabel">Update code</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                      id="close-modal"></button>
                  </div>
                  <form action="#" id="editform">
                    <div class="modal-body">
                      
                      <div class="mb-3" id="modal-id">
                        <label for="edit-code" class="form-label">Code</label>
                        <input type="text" id="edit-code" class="form-control" placeholder="Coupon Code" required
                          maxlength="50" />
                      </div>

                      <div class="mb-3" id="modal-id">
                        <label for="edit-points" class="form-label">Points</label>
                        <input type="text" id="edit-points" class="form-control" placeholder="Coupon points" required
                          maxlength="1" />
                      </div>

                      <div class="mb-3">
                        <label for="edit-authorize" class="form-label">Enable code after creation</label>
                        <div class="form-check form-switch form-switch-sm">
                          <input
                            class="form-check-input code-switcher"
                            type="checkbox"
                            id="edit-authorize"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                        <button type="button" class="btn btn-light" data-bs-dismiss="modal" id="edtclosemodal">
                          Close
                        </button>
                        <button type="button" class="btn btn-success" id="add-btn" @click="createCoupon">
                          Update Code
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <!-- Modal -->
            <div class="modal fade flip" id="deleteOrder" tabindex="-1" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-body p-5 text-center">
                    <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                      :height="90" :width="90" />
                    <div class="mt-4 text-center">
                      <h4>You are about to delete a order ?</h4>
                      <p class="text-muted fs-15 mb-4">
                        Deleting your order will remove all of your information
                        from our database.
                      </p>
                      <div class="hstack gap-2 justify-content-center remove">
                        <button class="btn btn-link link-success fw-medium text-decoration-none"
                          data-bs-dismiss="modal">
                          <i class="ri-close-line me-1 align-middle"></i> Close
                        </button>
                        <button class="btn btn-danger" id="delete-record">
                          Yes, Delete It
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end modal -->
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>

<script>
  import Swal from "sweetalert2";

  import Layout from "@/layouts/main.vue";
  import appConfig from "@/../app.config";
  import PageHeader from "@/components/page-header";
  import axios from 'axios';
  import animationData from "@/components/widgets/Lootie/animations/search.json";
  import Lottie from "@/components/widgets/Lootie";

  import { couponsApi } from '@/lib/api'

  export default {
    page: {
      title: "coupons",
      meta: [{
        name: "description",
        content: appConfig.description,
      }],
    },
    data() {
      return {
        title: "Achievements",
        breadcrumbs: [],
        page: 1,
        perPage: 8,
        pages: [],
        activePointCount: null,
        listCategory: 'all',
        searchQuery: null,
        config: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: "M j, Y",
          altInput: true,
          dateFormat: "d M, Y",
          mode: "range",
        },
        defaultOptions: {
          animationData: animationData
        },
        coupons: []
      };
    },
    components: {
      Layout,
      PageHeader,
      lottie: Lottie
    },
    computed: {
      couponsList() {
        return this.paginate(this.coupons);
      },
      resultQuery() {
        if (this.searchQuery) {
          const search = this.searchQuery.toLowerCase();
          return this.couponsList.filter((data) => {
            return (
              data.username.toLowerCase().includes(search) ||
              data.alias.toLowerCase().includes(search)
            );
          });
        } else {
          return this.couponsList;
        }
      },
    },
    watch: {},
    created() {
      this.setPages();
    },
    filters: {
      trimWords(value) {
        return value.split(" ").splice(0, 20).join(" ") + "...";
      },
    },
    beforeMount() {
      couponsApi.list().then(response => {
        const { success, result } = response
        if (success) {
          result.Items.forEach((row) => {
            this.coupons.push(row)
          })
          this.postProcessItems()
        } else {
          console.log(result)
        }
      })
    },
    mounted() {
      var checkAll = document.getElementById("checkAll");
      if (checkAll) {
        checkAll.onclick = function () {
          var checkboxes = document.querySelectorAll(
            '.form-check-all input[type="checkbox"]'
          );
          if (checkAll.checked == true) {
            checkboxes.forEach(function (checkbox) {
              checkbox.checked = true;
              checkbox.closest("tr").classList.add("table-active");
            });
          } else {
            checkboxes.forEach(function (checkbox) {
              checkbox.checked = false;
              checkbox.closest("tr").classList.remove("table-active");
            });
          }
        };
      }
    },
    methods: {
      setPages() {
        let numberOfPages = Math.ceil(this.coupons.length / this.perPage);
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      paginate(coupons) {
        let page = this.page;
        let perPage = this.perPage;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return coupons.slice(from, to);
      },
      createCoupon() {
        const code = document.getElementById('code').value;
        const points = document.getElementById('points').value;
        const authorize = document.getElementById('authorize').checked;
        if (code != null && authorize != null) {
          const newCoupon = {
            code,
            authorize,
            couponInfo: { points: Number(points) },
          }
          couponsApi.register(newCoupon).then(response => {
            const { success, result } = response
            if (success) {
              delete newCoupon.password
              this.coupons.push(newCoupon)
              this.postProcessItems()
              document.getElementById('closemodal').click();
              document.getElementById("addform").reset();
            } else {
              console.log(result)
            }
          }).catch(err => {
            console.log(err)
          }) 
        }
      },
      editCoupon(data) {
        let index = this.coupons.findIndex(o => o.username == data.username)
        document.getElementById('edit-code').value = this.coupons[index].code;
        document.getElementById('edit-authorize').checked = this.coupons[index].authorize;
      },
      postProcessItems() {
        
      },
      deletedata(event) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#f46a6a",
          confirmButtonColor: "#34c38f",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.value) {
            this.coupons.splice(this.coupons.indexOf(event), 1);
            axios.delete(process.env.VUE_APP_API_URL + `/apps/order/${event._id}`)
              .then(() => {
              }).catch((er) => {
                console.log(er)
              });
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          }
        });
      },
      deleteMultiple() {
        let ids_array = [];
        var items = document.getElementsByName("chk_child");
        items.forEach(function (ele) {
          if (ele.checked == true) {
            var trNode = ele.parentNode.parentNode.parentNode;
            var id = trNode.querySelector(".id a").innerHTML;
            ids_array.push(id);
          }
        });
        if (typeof ids_array !== "undefined" && ids_array.length > 0) {
          if (confirm("Are you sure you want to delete this?")) {
            var cusList = this.coupons;
            ids_array.forEach(function (id) {
              cusList = cusList.filter(function (coupons) {
                return coupons.username != id;
              });
            });
            this.coupons = cusList;
            document.getElementById("checkAll").checked = false;
            var itemss = document.getElementsByName("chk_child");
            itemss.forEach(function (ele) {
              if (ele.checked == true) {
                ele.checked = false
                ele.closest("tr").classList.remove("table-active");
              }
            });
          } else {
            return false;
          }
        } else {
          Swal.fire({
            title: "Please select at least one checkbox",
            confirmButtonClass: "btn btn-info",
            buttonsStyling: false,
            showCloseButton: true,
          });
        }
      },
      search() {
        return this.resultQuery
      },
      extractDate(datetime) {
        if (!datetime) {
          return null
        }
        const parts = datetime && datetime.split(' ')
        return parts[0]
      },
      extractTime(datetime) {
        if (!datetime) {
          return null
        }
        const parts = datetime && datetime.split(' ')
        return parts[1]
      },
      couponsEnable(event, member) {
        couponsApi.authorize(member.username, event.target.checked).then(response => {
          const { success, result } = response
          if (!success) {
            console.log(result)
          } else {
            member.enabled = event.target.checked
          }
        })
      }
    },
  };
</script>
